import { useEffect, useRef, useState } from "react";

const NewsletterForm = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [scale, setScale] = useState(1);
  const FORM_WIDTH = 540;
  const FORM_HEIGHT = 430;

  useEffect(() => {
    const updateScale = () => {
      if (containerRef.current) {
        const parentWidth =
          containerRef.current.parentElement?.offsetWidth ?? window.innerWidth;
        const availableWidth = Math.min(parentWidth - 32, 768);
        if (availableWidth < FORM_WIDTH) {
          setScale(availableWidth / FORM_WIDTH);
        } else {
          setScale(1);
        }
      }
    };

    updateScale();
    window.addEventListener("resize", updateScale);
    return () => window.removeEventListener("resize", updateScale);
  }, []);

  return (
    <div ref={containerRef} className="relative w-full max-w-2xl">
      <div
        className="absolute left-1/2"
        style={{
          transform: `translateX(-50%) scale(${scale})`,
          transformOrigin: "center top",
          width: FORM_WIDTH,
          height: scale === 1 ? FORM_HEIGHT : FORM_HEIGHT * scale,
        }}
      >
        <iframe
          src="https://cdn.forms-content-1.sg-form.com/1f8355e6-907a-11ef-abbf-22f8db5497e5"
          style={{
            width: FORM_WIDTH,
            height: FORM_HEIGHT,
            border: 0,
          }}
          title="Newsletter Signup Form"
        />
      </div>
      <div
        style={{ height: scale === 1 ? FORM_HEIGHT : FORM_HEIGHT * scale }}
      />
    </div>
  );
};

export default NewsletterForm;
