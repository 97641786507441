import { FunctionComponent, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Manifest from "../constants/Manifest";

interface HeaderProps {
  onBusinessClassClick?: () => void;
}

const Header: FunctionComponent<HeaderProps> = ({ onBusinessClassClick }) => {
  const [showList, setShowList] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const toggleButtonRef = useRef<HTMLImageElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        toggleButtonRef.current &&
        !toggleButtonRef.current.contains(event.target as Node)
      ) {
        setShowList(false);
      }
    };

    const handleScroll = () => {
      setShowList(false);
    };

    document.addEventListener("mousedown", handleClickOutside);
    window.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleBusinessClassClick = () => {
    setShowList(false);
    onBusinessClassClick?.();
  };

  return (
    <header className="sticky top-0 left-0 right-0 bg-gradient-to-r from-white/90 via-[#F5F3FF]/85 to-[#EDE9FF]/85 backdrop-blur-md border-b border-purple-100/20 shadow-[0_2px_12px_-4px_rgba(0,0,0,0.06)] items-center z-50 justify-start w-screen pc:text-[1.25rem] text-[1.125rem] font-medium font-body-lg-medium leading-[4.5rem] text-[#111827]">
      <div className="hidden md:flex items-center">
        <img
          onClick={openHome}
          className="w-[8rem] mr-[3rem] pc:ml-[6.563rem] ml-[2.53rem] cursor-pointer hover:opacity-80 transition-opacity"
          src="/magnify-tm.svg"
          alt=""
        />
        <nav className="flex items-center space-x-12">
          <span onClick={openBlogs} className="cursor-pointer relative group">
            <span className="relative z-10">Blogs</span>
            <span className="absolute bottom-0 left-0 w-full h-0.5 bg-[#7C3AED] scale-x-0 group-hover:scale-x-100 transition-transform duration-200 opacity-80"></span>
          </span>
          <span
            onClick={openClubVistaraSweetSpotsPage}
            className="cursor-pointer relative group"
          >
            <span className="relative z-10">Vistara Gems</span>
            <span className="absolute bottom-0 left-0 w-full h-0.5 bg-[#7C3AED] scale-x-0 group-hover:scale-x-100 transition-transform duration-200 opacity-80"></span>
          </span>
          {onBusinessClassClick && (
            <span
              onClick={handleBusinessClassClick}
              className="cursor-pointer relative group"
            >
              <span className="relative z-10">Free Business Class Alerts</span>
              <span className="absolute bottom-0 left-0 w-full h-0.5 bg-[#7C3AED] scale-x-0 group-hover:scale-x-100 transition-transform duration-200 opacity-80"></span>
            </span>
          )}
        </nav>
      </div>
      <div className="mx-[1rem] md:hidden flex flex-col bg-gradient-to-r from-white/90 via-[#F5F3FF]/85 to-[#EDE9FF]/85">
        <div className="flex items-center justify-between w-full py-[1rem]">
          <img
            onClick={openHome}
            className="w-[8rem] cursor-pointer"
            src="/magnify-tm.svg"
            alt=""
          />
          <img
            ref={toggleButtonRef}
            src={showList ? "/cross2.svg" : "/navbars.svg"}
            onClick={() => setShowList((prev) => !prev)}
            className="w-[1.172rem] transition-transform duration-200 hover:scale-110"
          />
        </div>
        {showList && (
          <div
            ref={dropdownRef}
            className="text-[#111827] animate-fadeIn bg-gradient-to-r from-white/90 via-[#F5F3FF]/85 to-[#EDE9FF]/85"
          >
            <div
              onClick={openHome}
              className="leading-normal text-[1.125rem] font-medium py-[1rem] cursor-pointer hover:bg-purple-50/50 transition-colors px-4"
            >
              Home
            </div>
            <div
              onClick={openBlogs}
              className="leading-normal text-[1.125rem] font-medium py-[1rem] cursor-pointer hover:bg-purple-50/50 transition-colors px-4"
            >
              Blogs
            </div>
            <div
              onClick={openClubVistaraSweetSpotsPage}
              className="leading-normal text-[1.125rem] font-medium py-[1rem] cursor-pointer hover:bg-purple-50/50 transition-colors px-4"
            >
              Vistara Gems
            </div>
            {onBusinessClassClick && (
              <div
                onClick={handleBusinessClassClick}
                className="leading-normal text-[1.125rem] font-medium py-[1rem] cursor-pointer hover:bg-purple-50/50 transition-colors px-4"
              >
                Free Business Class Alerts
              </div>
            )}
          </div>
        )}
      </div>
    </header>
  );

  function openHome() {
    window.open("/", "_self");
  }
  function openBlogs() {
    window.open(Manifest.blogUrl, "blank");
  }
  function openClubVistaraSweetSpotsPage() {
    navigate("/club-vistara-sweet-spots-before-merger");
  }
};

export default Header;
