import { FunctionComponent } from "react";

const HomeContainer: FunctionComponent = () => {
  function clickAppStoreLink() {
    window.open(
      "https://play.google.com/store/apps/details?id=com.wicked.magnify",
      "_blank"
    );
  }

  function clickPlayStoreLink() {
    window.open(
      "https://apps.apple.com/us/app/magnify-your-rewards-companion/id6449392706",
      "_blank"
    );
  }

  return (
    <div className="relative flex justify-center w-full h-[80rem] md:h-[70vh] pc:h-[calc(100vh-4.5rem)]">
      <div className="flex md:flex-row flex-col pc:w-[76rem] md:w-[48rem] w-full md:h-fit h-auto md:mt-[4rem] mt-0 pc:mx-[1.5rem] md:ml-[3.5rem] md:mr-[2rem] mx-[1.5rem] z-20">
        <div className="h-auto md:h-fit md:w-[26.5rem] md1:w-[32rem] pc:w-auto">
          <div className="md:text-left text-center md:mt-[4rem] mt-[2.5rem] mb-4">
            <a
              href="https://www.producthunt.com/posts/magnify-3?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-magnify-3"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=735983&theme=light"
                alt="Magnify - Manage your rewards across hotels, airlines, and cards | Product Hunt"
                className="w-[250px] h-[54px] md:mx-0 mx-auto"
              />
            </a>
          </div>
          <h1 className="md:text-left text-center m-0 mb-[1rem] pc:text-[3.75rem] md:text-[2.125rem] text-[2.1rem] text-[#111827] pc:leading-[4.5rem] leading-normal font-extrabold font-body-lg-medium">
            The easier way to manage rewards
          </h1>
          <p className="md:text-left text-center m-0 md:mb-[2rem] mb-[3.5rem] md:text-[1.125rem] text-[1rem] text-[#52525B] font-medium font-body-lg-medium pc:leading-[1.75rem] md:leading-[1.875rem] leading-normal">
            Keep track of your loyalty points across credit cards, airlines,
            hotels at one place.
          </p>
          <div className="hidden md:block">
            <div className="flex">
              <img
                onClick={clickAppStoreLink}
                src="/google-play.svg"
                className="pc:w-[13.875rem] w-[10.625rem] mr-[1.5rem] cursor-pointer"
                alt=""
              />
              <img
                onClick={clickPlayStoreLink}
                src="/app-store.svg"
                className="pc:w-[13.875rem] w-[10.625rem] cursor-pointer"
                alt=""
              />
            </div>
          </div>
        </div>
        <img
          src="/app-mockup.png"
          className="pc:w-[16rem] mx-auto w-[15.25rem] pc:mr-[3.75rem] md:mr-0"
          alt=""
        />
        <div className="visible md:hidden mt-[3.5rem]">
          <div className="flex flex-col items-center space-y-4">
            <img
              src="/google-play.svg"
              className="md:w-[16rem] w-[11rem]"
              alt=""
            />
            <img
              src="/app-store.svg"
              className="md:w-[16rem] w-[11rem]"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeContainer;
