import { useEffect, useRef, useState } from "react";
import Header from "../../components/header";
import "./club-vistara-sweet-spots.css";
import { airports } from "./airports";
import { useNavigate } from "react-router-dom";
import Manifest from "../../constants/Manifest";
import ReactGA from "react-ga4";
import { UaEventOptions } from "react-ga4/types/ga4";

export default function SearchPage() {
  const [city, setCity] = useState("");
  const [finalCity, setFinalCity] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const buyMeCoffeeURL = "https://buymeacoffee.com/magnify";

  function handleChange(event: any) {
    setCity(event?.target.value);
  }

  function handleClick(city: string) {
    const event: UaEventOptions & { [key: string]: any } = {
      category: "air india vistara merger",
      action: "click",
      label: "source city",
      value: 1,
      source_city: city,
    };
    if (process.env.NODE_ENV !== "development") {
      ReactGA.event(event);
    }
    setFinalCity(city);
  }

  const handleBlur = () => {
    setCity("");
    if (inputRef.current) {
      inputRef.current.blur();
    }
  };

  const filteredAirports =
    city === ""
      ? []
      : airports.filter(
          (airports) =>
            airports.city.toLowerCase().startsWith(city.toLowerCase()) ||
            airports.code.toLowerCase().startsWith(city.toLowerCase())
        );

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (finalCity) {
          const response = await fetch(
            `${Manifest.SERVER_URL}/reward/vistara-airindia-routes-comparison?city=${finalCity}`
          );
          const data = await response.json();
          navigate("/club-vistara-sweet-spots-before-merger/results", {
            state: {
              data: data.data,
            },
          });
        }
      } catch (error) {}
    };
    fetchData();
  }, [finalCity]);

  return (
    <div className="relative h-screen flex flex-col overflow-hidden">
      <Header />
      <a
        href={buyMeCoffeeURL}
        target="_blank"
        rel="noopener noreferrer"
        className="md:px-[1rem] px-0 md:py-[0.5rem] py-0 no-underline md:text-[1rem] text-[1.25rem] bg-white text-black font-dm md:border-2 border-0 border-[#FFDD00] border-solid font-bold rounded-full fixed md:right-[1rem] 3xs:right-[3rem] right-[3rem] md:top-[0.75rem] top-[1.2rem] hover:bg-slate-100 flex items-center h-auto z-[999]"
      >
        <img
          src="/coffee.gif"
          className="md:w-[2rem] md:h-[2rem] w-[1.7rem] h-[1.7rem] md:mr-2 mr-0"
        />
        <div className=" md:flex" hidden>
          Support Us
        </div>
      </a>
      <InMemoryOfCV />
      <div className="flex-1 overflow-y-auto bg-white w-full h-full flex flex-col items-center md:pt-12 pt-16">
        <div className="flex flex-col items-center justify-center">
          <div className="flex flex-row">
            <img
              className="lg:w-[8.5rem] md:w-[6rem] w-[4rem] lg:h-[8.5rem] md:h-[6rem] h-[4rem] md:mr-[2rem] mr-[1rem]"
              alt=""
              src="/club-vistara.svg"
            />
            <img
              className="lg:w-[8.5rem] md:w-[6rem] w-[4rem] lg:h-[8.5rem] md:h-[6rem] h-[4rem]"
              alt=""
              src="/air-india.svg"
            />
          </div>
          <h1 className="font-body-lg-medium m-0 lg:my-[2rem] md:my-[1.5rem] text-center my-[1rem] lg:text-[3rem] md:text-[2rem] text-[1.5rem] font-bold text-[#1C1939]">
            Air Vistara & Air India Sweet Spots
          </h1>
          <div className="relative lg:w-auto w-[92%]">
            <input
              type="text"
              placeholder="Enter Source Airport"
              value={city}
              onChange={handleChange}
              className="lg:w-[53rem] md:w-[calc(100%-4.3rem)] w-[calc(100%-2.3rem)] lg:h-[4.5rem] md:h-[3rem] h-[2rem] border-2 border-[#E0DCFF] focus:outline-none border-solid rounded-full lg:pl-[4rem] md:pl-[3rem] pl-[2rem] placeholder-text lg:text-[1.5rem] md:text-[1.2rem] text-[0.9rem] text-[#9E9E9E] custom-shadow"
            />
            <img
              className="lg:w-[1.7rem] md:w-[1.35rem] w-[1rem] lg:h-[1.7rem] md:h-[1.35rem] h-[1rem] absolute transform -translate-y-1/2 lg:left-[1.5rem] md:left-[1.1rem] left-[0.8rem] top-1/2"
              alt=""
              src="/lens.svg"
            />
            <img
              src="/cross.svg"
              alt="close"
              onClick={handleBlur}
              className="lg:h-[2rem] md:h-[1.6rem] h-[1.2rem] lg:w-[2rem] md:w-[1.6rem] w-[1.2rem] absolute transform -translate-y-1/2 top-1/2 lg:left-[54rem] md:left-[28.1rem] right-[0.7rem] cursor-pointer"
            />
            {Boolean(filteredAirports.length !== 0) && (
              <div className="border-solid border-2 border-[#E0DCFF] rounded-2xl absolute lg:top-[6rem] md:top-[4rem] top-[3rem] max-h-[20rem] overflow-y-scroll lg:w-auto w-[100%]">
                {filteredAirports.map((airport) => (
                  <div
                    onClick={() => handleClick(airport.city)}
                    className="flex flex-row bg-white md:w-[56rem] w-[100%] rounded-2xl hover:bg-[#e6e6e6] hover:rounded-none cursor-pointer"
                  >
                    <img
                      className="lg:w-[2rem] md:w-[1.55rem] w-[1.1rem] lg:h-[2rem] md:h-[1.55rem] h-[1.1rem] lg:mx-[1.5rem] md:mx-[1.15rem] mx-[0.8rem] lg:mt-[1.5rem] md:mt-[0.95rem] mt-[0.6rem]"
                      alt=""
                      src="/airplane.svg"
                    />
                    <div className="m-0 lg:py-[1.5rem] md:py-[1rem] py-[0.5rem]">
                      <p className="lg:text-[1.5rem] md:text-[1.2rem] text-[0.9rem] m-0 font-dm font-[700] text-[#484660] md:mb-[0.62rem] lg:mb-[0.75rem] mb-[0.5rem]">
                        {`${airport.city} (${airport.code})`}
                      </p>
                      <p className="lg:text-[1.1rem] md:text-[0.9rem] text-[0.7rem] m-0 font-dm font-normal text-[#484660]">
                        {airport.country}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="md:mt-[1rem] mt-[0.5rem]">
            <CityPills setFinalCity={setFinalCity} />
          </div>
        </div>

        <div className="px-4 md:px-8 mt-8 text-center text-sm text-[#484660] w-[90%]">
          <p className="font-body-lg-medium">
            <strong>What is it?</strong> Our tool lets you compare award points
            for Vistara and Air India routes. Select your source city to view
            all destinations and their point costs. It reveals valuable
            arbitrage opportunities, like finding flights to Doha up to 50%
            cheaper with Vistara than Air India, helping you get the most out of
            your rewards.
          </p>
        </div>
      </div>
    </div>
  );
}

function CityPills({
  setFinalCity,
}: {
  setFinalCity: (value: string) => void;
}) {
  const cities = ["Mumbai", "Bengaluru", "Delhi", "Hyderabad", "Chennai"];

  function handleClick(city: string) {
    setFinalCity(city);
  }

  return (
    <div className="flex flex-wrap justify-center space-x-2 md:space-x-4">
      {cities.map((city, index) => (
        <button
          key={index}
          onClick={() => handleClick(city)}
          className="lg:px-[1.25rem] px-[0.75rem] lg:py-[0.75rem] py-[0.5rem] cursor-pointer rounded-full text-[#484660] md:text-[1.1rem] text-[0.8rem] bg-white focus:outline-none border-2 border-[#E0DCFF] border-solid font-dm font-medium md:mb-[0] mb-[0.5rem]"
        >
          {city}
        </button>
      ))}
    </div>
  );
}

export function InMemoryOfCV() {
  return (
    <div className="w-full bg-gradient-to-r from-purple-50/80 via-purple-100/80 to-purple-50/80 py-4 px-4 sm:px-6">
      <div className="flex flex-col items-start justify-center gap-4 text-gray-600 font-dm max-w-[90%] mx-auto">
        <div className="flex items-center gap-3">
          <svg
            width="48"
            height="48"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="text-amber-500 sm:w-[12px] sm:h-[12px] w-12 h-12"
          >
            <path d="M15 14c.2-1 .7-1.7 1.5-2.5 1-.9 1.5-2.2 1.5-3.5A6 6 0 0 0 6 8c0 1 .2 2.2 1.5 3.5.7.7 1.3 1.5 1.5 2.5" />
            <path d="M9 18h6" />
            <path d="M10 22h4" />
          </svg>
          <span className="text-sm font-medium leading-normal text-left">
            This tool served us well before Air India's integration. While these
            sweet spots are no longer available, we keep it in memory of Club
            Vistara.
          </span>
        </div>
      </div>
    </div>
  );
}
